import React from 'react';
import { graphql } from 'gatsby';
import { HelmetDatoCms } from 'gatsby-source-datocms';
import Layout from '../components/Layout';
import PageHeader from '../components/PageHeader';
import CategoryProductList from '../components/CategoryProductList';
import ModularBlocks from '../components/ModularBlocks';

const ShopPage = ({
  data: {
    datoCmsShop: { seoMetaTags, title, modularBlocks },
    inStockProducts,
    giftCardProduct,
    outOfStockProducts,
  },
}) => (
  <Layout>
    <HelmetDatoCms seo={seoMetaTags} />
    <main>
      <PageHeader heading={title} />
      <CategoryProductList
        items={[
          ...inStockProducts.nodes,
          giftCardProduct,
          ...outOfStockProducts.nodes,
        ]}
      />
      <ModularBlocks items={modularBlocks} />
    </main>
  </Layout>
);

export const ShopPageQuery = graphql`
  query ShopPageQuery {
    datoCmsShop {
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      title
      modularBlocks {
        ...ContentModularBlockFragment
      }
    }
    inStockProducts: allShopifyProduct(
      filter: { totalInventory: { gt: 0 } }
      sort: { fields: updatedAt, order: DESC }
    ) {
      nodes {
        ...ProductCardFragment
      }
    }
    giftCardProduct: shopifyProduct(isGiftCard: { eq: true }) {
      ...ProductCardFragment
    }
    outOfStockProducts: allShopifyProduct(
      filter: { totalInventory: { eq: 0 }, isGiftCard: { eq: false } }
      sort: { fields: updatedAt, order: DESC }
    ) {
      nodes {
        ...ProductCardFragment
      }
    }
  }
`;

export default ShopPage;
